import * as React from "react";
import ProjectsApp from "projects";
import SEO from "../components/seo";

const ProjectScopePage = (): JSX.Element => {
  return (
    <React.Fragment>
      <SEO title="Projects" />
      <ProjectsApp basepath="projects" />
    </React.Fragment>
  );
};

export default ProjectScopePage;
